import React, { Component } from 'react';
import Session from '../../Session/Session';
import BaseService from '../Service/BaseService';

export class MenuLateral extends BaseService {

  state = {
    showMenu: false,
    name: '',
    empresa: '',
  }

  componentDidMount() {
    const session = new Session();
    this.setState({ name: session.getName(), empresa: session.getEmpresa() });
  }

  logoff() {

    const session = new Session();
    session.destroySession();    
  }

  render() {
    return (
      <div>
        <div className='all-menu' style={!this.state.showMenu ? { display: 'none' } : { display: 'block' }}>
          <div className='menu_lateral'>
            <div className='header_8'>
              <div className='align_carrefour'>
                <div className={this.state.empresa == 1 ? 'logo_carrefour' : 'logo_atacadao'}></div>
                <button className='img_close' onClick={() => this.setState({ showMenu: false })}></button>
              </div>
            </div>
            <div className='header_9'>
              <div className='align_text_1'>
                <div className='text_menu_1'>
                  <label className='text_bold_menu text-capitalize'> {this.state.name} </label>
                </div>
              </div>
            </div>
            <div className='header_10'>
              <a href="/home">
                <button className='bt_1_1'>
                  <div className='img_home'></div>
                  <div className='text_envelop'>HOME</div>
                </button>
              </a>
              <a href="/contact">
                <button className='bt_1_1'>
                  <div className='img_chat'></div>
                  <div className='text_chat_1'>ATENDIMENTO</div>
                </button>
              </a>
              <button className='bt_1_1' onClick={() => this.logoff()}>
                <div className='img_sair_2'></div>
                <div className='text_chat_1'>SAIR</div>
              </button>
            </div>
          </div>
          <div className='blackout' onClick={() => this.setState({ showMenu: false })}>
          </div>
        </div>
        <div className='bg_1'>
          <div className='header_1'>
            <div className='container-header'>
              <button className='img_menu' onClick={() => this.setState({ showMenu: true })}>
                <div className='text_menu'>Menu</div>
              </button>
              <div className='logo_services'>
              </div>
              <button className='img_sair' onClick={() => this.logoff()}>
                <div className='text_sair'>Sair</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default MenuLateral;
