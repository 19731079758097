let link = window.location.origin;

let url = '';
let prod = false;
if (link === 'http://localhost:3000') {
    url = 'http://localhost:8095';
    prod = false;
} else if (link === 'https://homologregularizaescob.servicesdigital.com.br') {
    url = 'https://homologregularizaescob.servicesdigital.com.br/api-escob/public';
    prod = true;
} else if (link === 'https://hml.regularizaescob.servicesdigital.com.br') {
    url = 'https://hml.regularizaescob.servicesdigital.com.br/api-escob/public';
    prod = true;
} else {
    url = 'https://regularizaescob.servicesdigital.com.br/api-escob/public';
    prod = true;
}

const urlApi = [
    {
        api: url,
        prod: prod
    }
];

export default urlApi; 