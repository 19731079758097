import React, { Component } from 'react';

import Menu from '../Home/MenuLateral';
import Footer from '../Footer/Footer';

import axios from 'axios';
import Session from '../../Session/Session';
import api from '../Environment/Environment';
import BaseService from '../Service/BaseService';

export class ThankYouPage extends BaseService {

    constructor(props) {
        super(props);
        const session = new Session();

        this.state = {
            cpf: session.getCpf(),
            name: session.getName(),
        }

        this.registerPage(546);
    }

    // async registerPage(recurso) {
    //     await axios.post(`${api[0].api}/clientes/register-page`,
    //         {
    //             recurso: recurso,
    //             url: window.location.href,
    //             resolucao: window.screen.height + 'x' + window.screen.width,
    //             telefone: '',
    //             email: '',
    //             nome: this.state.name,
    //             cpf: this.state.cpf
    //         },
    //         {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
    //                 "Accept": "/",
    //                 "cache-control": "no-cache"
    //             }
    //         }).then(res => {
    //         })
    // }


    render() {
        return (
            <div>
                <Menu />
                <div className="align-content-1">
                    <div className="header_2">
                        <div className="text_inicio">Agradecemos a sua solicitação.</div>
                    </div>
                    <div className="header_3">
                        <div className="text_click">
                            <label className="text_color">Registramos sua informação em nosso sistema, obrigado pelo contato.</label><br></br>
                        </div>
                        <div className="row"></div>
                        <div className="row">
                        </div>
                        <div className="header_6">
                            <a href="/home">
                                <div className="header_6">
                                    <button className="bt_voltar" id="abrir-modal">Voltar</button>
                                </div>
                            </a>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default ThankYouPage;