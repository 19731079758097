import React, { Component } from 'react';

import Menu from './MenuLateral';
import Footer from '../Footer/Footer';
import Loading from '../Loading/Loading';
import Session from '../../Session/Session';
import api from '../Environment/Environment';
import axios from 'axios';
import BaseService from '../Service/BaseService';

export class Home extends BaseService {

    //Tela Home 
    constructor(props) {
        const session = new Session();
        session.callApiToken('/validar-token');

        super(props);
        this.state = {
            session: session,
            cpf: session.getCpf(),
            name: session.getName(),
            acordo: 'true' === session.getAcordo(),
            contract: session.getContract(),
            loading: false,
            value: session.getValueDebt(),
            dias: session.getDiasAtraso()
        };

        this.registerPage(537);
    }


    render() {

        let valueTitle = '';
        let diasAtrasoTit = '';
        let value = '';
        let diasAtrasoVal = '';

        if (this.state.acordo){
            if (typeof this.state.value == 'undefined'){
                valueTitle = <label className="text_color_1">Valor parcela: </label>;
                value = this.state.value;
            }
        }else{
            valueTitle = <label className="text_color_1">Valor: </label>;
            value = this.state.value;
            diasAtrasoTit = <label className="text_color_1">Dias em atraso: </label>;
            diasAtrasoVal = this.state.dias;
        }

        return (
            <div>
                <Menu />
                {this.state.loading ? <Loading /> : ''}
                <div className="align-content-1">
                    <div className="header_2">
                        <div className="text_inicio">
                            Olá <label className="text_bold text-capitalize">{this.state.name},</label><br></br>
                            Este é um canal para você regularizar seus débitos de modo rápido e seguro.
                        </div>
                    </div>
                    <div className="box_dados_3">
                        <div className="box_detalhe_3">
                            <div className="box_detalhe">
                                <div className="text_contrato">
                                    <label className="text_color_1">Contrato: </label>{this.state.contract}<br></br>
                                    {valueTitle}{value}<br></br>
                                    {diasAtrasoTit}{diasAtrasoVal}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header_3">
                        <div className="text_click">Escolha uma das opções abaixo para realizar seu atendimento:</div>
                        <div className="align-bts">
                            {this.state.acordo ?
                                <div className="align-bt-row">
                                    <a href="/select-parcel">
                                        <button className="bt-default">
                                            <div className="img_doc"></div>
                                            <div className="text_copy">Consultar acordo</div>
                                        </button>
                                    </a>
                                    <a href="/payment-made">
                                        <button className="bt-default">
                                            <div className="img_coin"></div>
                                            <div className="text_copy">Já realizei o pagamento</div>
                                        </button>
                                    </a>
                                </div>
                                :
                                <div className="align-bt-row">
                                    <a href="/select-date">
                                        <button className="bt-default">
                                            <div className="img_doc"></div>
                                            <div className="text_copy">Simular acordo</div>
                                        </button>
                                    </a>
                                    <a href="/payment-made">
                                        <button className="bt-default">
                                            <div className="img_coin"></div>
                                            <div className="text_copy">Já realizei o pagamento</div>
                                        </button>
                                    </a>
                                </div>
                            }
                            <div className="align-bt-row align-center">
                                <a href="/contact">
                                    <button className="bt-default">
                                        <div className="img_call"></div>
                                        <div className="text_copy">Atendimento</div>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
};

export default Home;
