import { Component } from 'react';
import axios from 'axios';
import Session from '../../Session/Session';
import api from '../Environment/Environment';

export class BaseService extends Component {

    constructor(props) {
        super(props);
        var session = new Session();

        this.state = {
            cpf: session.getCpf(),
            codTit: session.getCodTit(),
        };
    }

    async registerPage(recurso) {
        await axios.post(`${api[0].api}/clientes/register-page`,
            {
                recurso: recurso,
                url: window.location.href,
                resolucao: window.screen.height + 'x' + window.screen.width,
                telefone: '',
                email: '',
                nome: this.state.session.getName(),
                cpf: this.state.session.getCpf(),
                idCarteira: 236,
                contrato: this.state.session.getContract(),
                cod_tit: this.state.session.getCodTit(),
                AcessoPJ: 0,
                custom1: this.state.session.getSegundaVia() == "true" ? 'segunda via' : ''
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
                    "Accept": "/",
                    "cache-control": "no-cache"
                }
            }).then(res => {
        })
    }

    callApiBack = async (params, uri) => {

        await axios.post(`${api[0].api}${uri}`,
            {
                cpf: this.state.cpf,
                codigoTitulo: this.state.codTit,
            },
            {
                headers: {
                    "Accept": "/",
                    "cache-control": "no-cache",
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh"
                }
            }).then(res => {

                if (res.data.status) {
                    return res.data
                } else {
                    return false;
                }
            }).catch(err => {         
            })
    }


}

export default BaseService;