import React, { Component } from 'react';

import Calendar from 'react-calendar';
import Menu from '../Home/MenuLateral';
import Footer from '../Footer/Footer';

import Modal from 'react-awesome-modal';

import axios from 'axios';
import Session from '../../Session/Session';
import Loading from '../Loading/Loading';
import api from '../Environment/Environment';

import BaseService from '../Service/BaseService';

export class PaymentMade extends BaseService {

    constructor(props) {

        let session = new Session();
        session.callApiToken('/validar-token');

        super(props);
        this.state = {
            session:session,
            name: session.getName(),
            loading: false,
            cpf: session.getCpf(),
            codTit: session.getCodTit(),
            contract: session.getContract(),
            diasAtraso: session.getDiasAtraso(),
            visible: false,
            startDate: new Date(),
            now: new Date(),
            messageModal: '',
        };
        this.registerPage(545);
        this.handleChange = this.handleChange.bind(this);
    }

    // async registerPage(recurso) {
    //     await axios.post(`${api[0].api}/clientes/register-page`,
    //         {
    //             recurso: recurso,
    //             url: window.location.href,
    //             resolucao: window.screen.height + 'x' + window.screen.width,
    //             telefone: '',
    //             email: '',
    //             nome: this.state.name,
    //             cpf: this.state.cpf
    //         },
    //         {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
    //                 "Accept": "/",
    //                 "cache-control": "no-cache"
    //             }
    //         }).then(res => {
    //         })
    // }
    
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    openModal() {
        this.setState({ visible: true })
    }

    closeModal() {
        this.setState({ visible: false });
    }

    sendForm() {

        let valueInformed = document.getElementById('value-informed').value;

        if (valueInformed == '') {
            this.state.messageModal = ' informe o valor pago.'
            this.openModal();
            return false;
        }

        var returnCheck = this.checkValueInformed(valueInformed);
        if (!returnCheck) {
            this.state.messageModal = ' informe um valor diferente de zero.'
            this.openModal();
            return false;
        }

        this.setState({ loading: true })
        axios.post(`${api[0].api}/pagamentos/alega-pagamento`,
            {
                cpf: this.state.cpf,
                codTit: this.state.codTit,
                contrato: this.state.contract,
                valorPago: valueInformed,
                dataPagamento: this.state.startDate,
                diasAtraso: this.state.diasAtraso,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
                    "Accept": "/",
                    "cache-control": "no-cache"
                }
            }).then(res => {

                if (res.status) {
                    window.location.href = '/thank-you';
                } else {
                    window.location.href = '/home';
                }
            })

    }

    checkValueInformed(value) {

        value = value.replace('.', '');
        value = value.slice(2).trim();

        var flag = false;
        for (var i = 0; i <= value.length; i++) {
            var j = value[i];

            if (j != 0 && j != '.' && j != undefined) flag = true;
        }

        return flag;
    }

    convertNumber(z) {

        var v = z.target.value;
        v = v.replace(/\D/g, "");
        v = v.replace(/(\d{1})(\d{8})$/, "$1.$2");
        v = v.replace(/(\d{1})(\d{5})$/, "$1.$2");
        v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");

        document.getElementById('value-informed').value = 'R$ ' + v;
    }

    render() {
        return (
            <div>
                <Menu />
                {this.state.loading ? <Loading /> : ''}
                <div className="align-content-1">
                    <div className="header_2">
                        <div className="text_inicio">Já realizei o pagamento</div>
                    </div>
                    <div className="header_3">
                        <div className="text_click">
                            <label className="text_color">Registre abaixo a data em que realizou o pagamento:</label><br></br>
                        </div>
                        <div className="row"></div>
                        <div className="container_formulario">
                            <div className="text_formulario">Valor pago:</div>
                            <input className="formulario" id="value-informed" onChange={this.convertNumber} name="value-informed" type="text" placeholder="Ex: R$ 157,11"></input>
                            <div className="line_formulario">
                            </div>
                            <div className="text_formulario_1">Data de pagamento:</div>
                            <Calendar onChange={this.handleChange} value={this.state.startDate} maxDate={this.state.now} calendarType="US"/>
                            <div className="line_formulario"></div>
                        </div>
                        <div className="row">
                        </div>
                        <div className="header_6">
                            <button className="bt_enviar" id="abrir-modal" onClick={() => this.sendForm()}>Continuar</button>
                            <a href="/home">
                                <div className="header_6">
                                    <button className="bt_voltar" id="abrir-modal">Voltar</button>
                                </div>
                            </a>
                        </div>
                    </div>
                    <Modal visible={this.state.visible} width="400" height="300" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <div>
                            <div className="box-modal">
                                <div className="text_modal"></div>
                                {/*<div className="line_login"></div>*/}
                                <label className="text_color">ATENÇÃO!{this.state.messageModal}</label><br></br>
                                <button onClick={() => this.closeModal()} className="bt_modal">FECHAR</button>
                            </div>
                        </div>
                    </Modal>
                    <Footer />
                </div>
            </div >
        )
    }
}

export default PaymentMade;