import React from 'react';
import Header from './Components/Header/Header';
import Body from './Components/Body/Body';
import Footer from './Components/Footer/Footer';


const App = () => (
  <div className="bg">
    <Header />
    <div className="align-content-initial">
      <Body />
      <Footer />
    </div>

  </div>
);

export default App;
