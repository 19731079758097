import React, { Component } from 'react';

import Menu from '../Home/MenuLateral';
import Footer from '../Footer/Footer';

import Session from '../../Session/Session';
import BaseService from '../Service/BaseService';

export class ReturnContactLigacao extends BaseService {

    constructor(props) {
        super(props);
        const session = new Session();
        session.callApiToken('/validar-token');       
    }

    render() {
        return (
            <div>
                <Menu />
                <div className="align-content-1">
                    <div className="header_2">
                        <div className="text_inicio">Agradecemos a sua solicitação.</div>
                    </div>
                    <div className="header_3">
                        <div className="text_click">
                            <label className="text_color">Registramos a informação, logo um de nossos atendentes entrará em contato pelo telefone informado.</label><br></br>
                        </div>
                        <div className="row"></div>
                        <div className="row">
                        </div>
                        <div className="header_6">
                            <a href="/home">
                                <div className="header_6">
                                    <button className="bt_voltar" id="abrir-modal">Voltar</button>
                                </div>
                            </a>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default ReturnContactLigacao;