import React, { Component } from 'react';
import BaseService from '../Service/BaseService';


export class Footer extends BaseService {

    constructor(props) {

        super(props);
        this.state = {
            pathName: window.location.pathname
        }
    }

    render() {
        return (
            <footer>
                <div className="container-footer">
                    <div className="img_rodape"></div>
                    <div className="line_rodape"></div>
                    <div className={this.state.pathName === '/' ? 'rodape' : 'rodape-black'}>
                        Copyright 2019. Todos os direitos reservados ao Grupo Services |
                        <a className={this.state.pathName === '/' ? 'politica' : 'politica-black'} href="/politicaprivacidade">Politica de privacidade</a>.
                </div>
                </div>
            </footer>
        )
    }
};

export default Footer;