import React, { Component } from 'react';

import Footer from '../Footer/Footer';
import Menu from '../Home/MenuLateral';
import Loading from '../Loading/Loading';
import Session from '../../Session/Session';
import api from '../Environment/Environment';
import axios from 'axios';
import BaseService from '../Service/BaseService';

export class Privacidade extends BaseService {

    constructor(props) {
        const session = new Session();

        super(props);
        this.state = {
            session: session,
            cpf: session.getCpf(),
            name: session.getName(),
            contract: session.getContract(),
            loading: false,
        };
    }

    render() {

        return (
            <div>
                <Menu />
                <div className="align-content-1">
                    <div className="header_2">
                        <div className="text_inicio">Política de privacidade</div>
                    </div>

                    <div className="row center column">
                        <div className="text-privacidade">

                            <b className="text-privacidade-title">Política de Privacidade - Descritivo</b>
                            <p>O objetivo deste documento é apresentar a Política de Privacidade do Grupo Services para os
                                serviços e produtos que utilizarão informações pessoais de clientes coletadas em plataformas
                                web. <br></br> <br></br>

                                    O Grupo Services (Services Assessoria e Cobrança EIRELI) é comprometido em guardar sua
                                    privacidade.<br></br>
                                    Garantimos que suas informações pessoais não serão utilizadas indevidamente através de spam,
                                    venda de informações e outros.<br></br>
                                    Estas informações serão utilizadas para ajudar a tornar a navegação mais produtiva e a
                                    experiência do usuário mais agradável.<br></br>

                                    Os produtos e serviços do Grupo Services possuem ligações com outros sites; no entanto, esta
                                    Política de Privacidade se não se aplica a sites de terceiros.<br></br><br></br>

                                    Ao acessar e utilizar os serviços/produtos disponibilizados, você concorda com os termos
                                    desta
                                    política.
                            </p>

                            <b className="text-privacidade-title">Coleta das Informações: </b>

                            <p>Nos portais on-line, as informação são coletadas da seguinte maneira:<br></br>
                                - Preenchimento de formulários: CPF pe solicitado para
                                validação e é previamente fornecido por nossos contratantes;<br></br>
                                - Navegação no site: O Grupo Services utiliza as ferramentas proprietárias Adsense, Google
                                Analytics e Google Adwords que identificam informações através de cookies, como IP,
                                localização geográfica, tipo de navegador utilizado durante a utilização dos portais;<br></br>
                                - Controle de navegabilidade: todos os acessos aos portais são mapeados e analisados por
                                ferramentas proprietárias para analisar a navegabilidade e controlar a segurança/histórico
                                via token de registro único.
                            </p>

                            <b className="text-privacidade-title">Uso das Informações:</b>

                            <p>As informações coletadas serão utilizadas para validação/exibição dos dados solicitados,
                                geração de relatórios estratégicos e mapeamento de oportunidades para melhor
                                atendimento.<br></br>
                                    E-mail e/ou telefone serão utilizados para envios de informações solicitadas durante a
                                interação no portal online.<br></br>
                                    Uso para estatísticas e levantamento de navegabilidade das plataformas utilizadas.
                                    Apenas uma equipe interna devidamente autorizada terá acesso às informações coletas.
                                    Todo o acesso aos sistemas online será registrado em logs. As informações coletadas são: IP,
                                    Versão Navegador, dispositivo utilizado, dados demográficos, localização geográfica, token
                                    de acesso, entre outros.
                            </p>

                            <b className="text-privacidade-title">Nossos contatos:</b>

                            <p>    O Grupo Services (CNPJ: 00584567/0001-52), está localizado na Rua José Loureiro, 371 –
                                Centro – Curitiba – PR – CEP 80010-010.
                            </p>

                            <b className="text-privacidade-title">Atualização desta Política de Privacidade:</b>

                            <p>Como esta política pode passar por atualizações, recomenda-se que periodicamente seja
                                acessado para conhecimento das modificações. As informações não será utilizadas para outros
                                fins que não os detalhados aqui nesta Política de Privacidade.
                            </p>

                        </div>
                    </div>

                    <div className="row center">
                        <a href="/home">
                            <p>Voltar</p>
                        </a>
                    </div>
                    <Footer/>
                </div>
            </div>
        )
    }
};

export default Privacidade;
