import React, { Component } from 'react';
import Session from '../../Session/Session';

import Menu from '../Home/MenuLateral';
import Footer from '../Footer/Footer';
import Modal from 'react-awesome-modal';
import Loading from '../Loading/Loading';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import api from '../Environment/Environment';
import axios from 'axios';
import BaseService from '../Service/BaseService';

export class ViewAcordo extends BaseService {

    constructor(props) {
        const session = new Session();
        session.callApiToken('/validar-token');

        super(props);
        this.state = {
            copied: false,
            session: session,
            empresa: session.getEmpresa(),
            visibleModal: false,
            codTit: session.getCodTit(),
            cpf: session.getCpf(),
            name: session.getName(),
            urlBoleto: session.getUrlBoleto(),
            fileName: session.getFileName(),
            acordo: session.getAcordo(),
            contract: session.getContract(),
            barCode: session.getLinhaDig(),
            valorParcela: session.getValueDebt(),
            loading: true,
            vencimentoParc: session.getVencimento(),
        }
        this.registerPage(542);
        this.callApiSearchParcel();
    }

    async callApiSearchParcel() {

        await axios.post(`${api[0].api}/acordos/consultar-parcelas`,
            {
                cpf: this.state.cpf,
                codigoTitulo: this.state.codTit
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
                    "Accept": "/",
                    "cache-control": "no-cache"
                }
            }).then(res => {
            if (res.data.status) {
                this.state.session.setValueDebt(res.data.data.valorParcela);
                localStorage.setItem('parcelas', JSON.stringify(res.data.data));
                this.callApiAcordo();
            } else {
                localStorage.clear();
                this.setState({ loading: false, visibleModal: true, messageField: 'Houve um erro no sistema, tente novamente mais tarde.' });
                return false;
            }
        })

    }

    async callApiAcordo() {

        var parcelas = JSON.parse(localStorage.getItem('parcelas'));

        var flag = false;
        if (parcelas == null || parcelas == undefined) {
            flag = true;
        } else {
            flag = parcelas.boletoPendente;
        }

        this.setState({ loading: true })
        await axios.post(`${api[0].api}/acordos/consultar-acordo`,
            {
                documento: this.state.cpf,
                credor: 236,
                empresa: this.state.empresa,
                contrato: this.state.contract,
                codigoTitulo: this.state.codTit,
                boletoPendente: flag,
                parcelaId: localStorage.getItem('parc-selected'),
                codParcela: parcelas.codParcela,
                segundaVia: this.state.session.getSegundaVia()
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
                    "Accept": "/",
                    "cache-control": "no-cache"
                }
            }).then(res => {

            if (res.data.status) {
                let returnApi = res.data.data;

                this.setState({
                    fileName: returnApi.fileName,
                    urlBoleto: returnApi.urlBoleto,
                    barCode: returnApi.linhaDigitavel,
                    valorParcela: returnApi.valor,
                    vencimentoParc: returnApi.vencimento,
                    loading: false,
                })
            } else {
                this.setState({ loading: false, visibleModal: true, messageField: 'Houve um erro no sistema, tente novamente mais tarde.' });
                return false;
            }
        })
    }

    openModalBarCode() {
        document.getElementById('codigo-aberto').style.display = 'block';
    }

    closeBarCode() {
        document.getElementById('codigo-aberto').style.display = 'none';
    }

    downloadBoleto() {

        this.registerPage(544);
        window.open(api[0].api + this.state.urlBoleto, '_blank');
    }

    render() {
        return (
            <div>
                <Menu />
                {this.state.loading ? <Loading /> : ''}
                <div className="align-content-1">
                    <div className="header_2">
                        <div className="text_inicio">Acordo da dívida</div>
                    </div>
                    <div className="container-1">
                        <div className="header_3">
                            <div className="text_click">
                                <label className="text_color_1 text-capitalize ">{this.state.name}</label>, abaixo segue o detalhamento do <br></br>
                                seu contrato <label className="text_color_1">{this.state.contract} </label>
                            </div>
                            <div className="row"></div>
                            <div className="box_dados_3">
                                <div className="box_detalhe_3">
                                    <div className="box_detalhe">
                                        <div className="text_contrato">
                                            <label className="text_color_1">Valor da parcela:</label>    {this.state.valorParcela}<br></br>
                                            <label className="text_color_1">Vencimento:</label>    {this.state.vencimentoParc}<br></br>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row"></div>
                            <div className="row space-between align-bts-mobile">
                                <button className="bt_cont_1" id="botao-abrir-codigo" onClick={() => this.openModalBarCode()}>Copiar código</button>
                                <button className="bt_cont_1" id="imprimir" onClick={() => this.downloadBoleto()}>Imprimir</button>
                            </div>
                            <div className="row align-center align-bts-mobile">
                                <a href="/home" className="bt_voltar_1">
                                    Voltar
                                </a>
                            </div>
                        </div>
                        <div className="all-modal" id="codigo-aberto">
                            <div className="box_dados_1">
                                <div className="box_detalhe_1"></div>
                                <div className="text_codigo">CÓDIGO DE BARRAS</div>
                                <div className="box_cod">
                                    <div className="cod_text">{this.state.barCode}</div>
                                    <CopyToClipboard text={this.state.barCode} onCopy={() => this.setState({ copied: true, visibleModal: true })}>
                                        <button className="bt_cod">
                                            <div className="img_copy"></div>
                                            <div className="text_cod_copy" id="copiar-codigo-barras" onClick={() => this.registerPage(543)}>Copiar</div>
                                        </button>
                                    </CopyToClipboard>
                                </div>
                                <button className="bt_close-1" onClick={() => this.closeBarCode()}>
                                    <div className="text_close_1">Fechar</div>
                                </button>
                            </div>
                            <div className="blackout_1"></div>
                        </div>
                    </div>
                    <Modal visible={this.state.visibleModal} width="400" height="300" effect="fadeInUp" onClickAway={() => this.setState({ visibleModal: false })}>
                        <div>
                            <div className="box-modal">
                                <div className="text_modal">SUCESSO</div>
                                <h5 className="message-modal-wpp">Código copiado com sucesso!</h5>
                                <button onClick={() => this.setState({ visibleModal: false })} className="bt_modal">FECHAR</button>
                            </div>
                        </div>
                    </Modal>
                    <Footer />
                </div>
            </div>
        )
    }
};

export default ViewAcordo;