import React, { Component } from 'react';

import Session from '../../Session/Session';
import axios from 'axios';
import api from '../Environment/Environment';
import BaseService from '../Service/BaseService';

export class Body extends BaseService {

  constructor(props) {

    super(props);

    axios.post(`${api[0].api}/clientes/register-page`,
      {
        recurso: 533,
        url: window.location.href,
        resolucao: window.screen.height + 'x' + window.screen.width,
        telefone: '',
        email: '',
        nome: '',
        cpf: ''
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
          "Accept": "/",
          "cache-control": "no-cache"
        }
      }).then(res => {
      })

  }

  redirect(value) {

    let session = new Session();
    session.setEmpresa(value);

    window.location.href = '/login';
  }

  render() {
    return (
      <div className="body-select">
        <div className="align_text_01">
          <div className="text_01">Selecione a empresa</div>
        </div>
        <div className="row"></div>
        <div className="container">
          <button className="bt_atacadao" onClick={() => this.redirect(2)}>
            <div className="logo_atacadao_home"></div>
          </button>
          <button className="bt_carrefour" onClick={() => this.redirect(1)}>
            <div className="logo_carrefour"></div>
          </button>
        </div>
      </div>
    )
  }
};

export default Body;
