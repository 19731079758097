import axios from 'axios';
import api from '../Components/Environment/Environment';

class Session {

    constructor() {
        this.name = '';
        this.cpf = '';
        this.contract = '';
        this.codTit = '';
        this.valueDebt = '';
        this.acordo = '';
        this.empresa = '';
        this.token = '';
        this.fileName = '';
        this.linhaDig = '';
        this.urlBoleto = '';        
        this.vencimento = '';
        this.diasAtraso = '';
        this.valorParcela = '';
        this.segundaVia = '';
    }
    setName(name) {
        this.name = name;
        localStorage.setItem('name', name);
    }

    setCpf(cpf) {
        this.cpf = cpf;
        localStorage.setItem('cpf', cpf);
    }

    setContract(contract) {
        this.contract = contract;
        localStorage.setItem('contract', contract);
    }

    setCodTit(codTit) {
        this.codTit = codTit;
        localStorage.setItem('codTit', codTit);
    }

    setValueDebt(valueDebt) {
        this.valueDebt = valueDebt;
        localStorage.setItem('valueDebt', valueDebt);
    }

    setAcordo(acordo) {
        this.acordo = acordo;
        localStorage.setItem('acordo', acordo);
    }

    setEmpresa(empresa) {
        this.empresa = empresa;
        localStorage.setItem('empresa', empresa);
    }

    setToken(token) {
        this.token = token;
        localStorage.setItem('token', token);
    }

    setFileName(fileName) {
        this.fileName = fileName;
        localStorage.setItem('fileName', fileName);
    }

    setLinhaDig(linhaDig) {
        this.linhaDig = linhaDig;
        localStorage.setItem('linhaDig', linhaDig);
    }

    setUrlBoleto(urlBoleto) {
        this.urlBoleto = urlBoleto;
        localStorage.setItem('urlBoleto', urlBoleto);
    }

    setVencimento(vencimento) {
        this.vencimento = vencimento;
        localStorage.setItem('vencimento', vencimento);
    }

    setDiasAtraso(dias){
        this.diasAtraso = dias;
        localStorage.setItem('dias', dias);
    }

    setValorParcela(valorParcela){
        this.valorParcela = valorParcela;
        localStorage.setItem('valorParcela', valorParcela);
    }

    setSegundaVia(segundaVia){
        this.segundaVia = segundaVia;
        localStorage.setItem('segundaVia', segundaVia);
    }

    setExpedienteAtendimento(segundaVia){
        this.segundaVia = segundaVia;
        localStorage.setItem('segundaVia', segundaVia);
    }

    getName() {
        return localStorage.getItem('name');
    }

    getCpf() {
        return localStorage.getItem('cpf');
    }

    getContract() {
        return localStorage.getItem('contract');
    }

    getCodTit() {
        return localStorage.getItem('codTit');
    }

    getValueDebt() {
        return localStorage.getItem('valueDebt');
    }

    getAcordo() {
        return localStorage.getItem('acordo');
    }

    getEmpresa() {
        return localStorage.getItem('empresa');
    }

    getToken() {
        return localStorage.getItem('token');
    }

    getFileName() {
        return localStorage.getItem('fileName');
    }

    getLinhaDig() {
        return localStorage.getItem('linhaDig');
    }

    getUrlBoleto() {
        return localStorage.getItem('urlBoleto');
    }

    getVencimento() {
        return localStorage.getItem('vencimento');
    }

    getDiasAtraso(){
        return localStorage.getItem('dias');
    }

    getValorParcela(){
        return localStorage.getItem('valorParcela');
    }

    getSegundaVia(){
        return localStorage.getItem('segundaVia');
    }

    destroySession() {

        this.callApiToken('/realizar-logoff');
        localStorage.clear();
        window.location.href = '/';
    }

    async callApiToken(route) {

        if (!this.getCpf() || !this.getToken()) window.location.href = '/';

        await axios.post(`${api[0].api}/clientes${route}`,
            {
                cpf: this.getCpf(),
                token: this.getToken(),
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
                    "Accept": "/",
                    "cache-control": "no-cache"
                }
            }).then(res => {

                if (res.data.status) {
                    return true;
                } else {
                    this.destroySession();
                    window.location.href = '/';
                }
            })
    }

    async callExpedienteAtendimento(route) {

        return await axios.post(`${api[0].api}/contato${route}`,
            {
                token: this.getToken(),
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
                    "Accept": "/",
                    "cache-control": "no-cache"
                }
            }).then(res => {

            if (res.data.status === true) {
                return true;
            } else {
                return false;
            }
        })
    }
}

export default Session;