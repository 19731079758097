import React, { Component } from 'react';

import axios from 'axios';

import Menu from '../Home/MenuLateral';
import Footer from '../Footer/Footer';

import Modal from 'react-awesome-modal';
import Session from '../../Session/Session';
import Loading from '../Loading/Loading';
import api from '../Environment/Environment';
import BaseService from '../Service/BaseService';

export class Contact extends BaseService {

    constructor(props) {

        let session = new Session();
        session.callApiToken('/validar-token');

        super(props);
        this.state = {
            session: session,
            nome: session.getName(),
            cpf: session.getCpf(),
            fieldEmpty: false,
            modalEmail: false,
            loading: true,
            modalLigacao: false,
            modalWhatsApp: false,
        }

        this.callExpedienteAtendimento('/verificar-expediente');

        this.registerPage(553);
    }

    closeModal() {
        this.setState({ visibleModal: false });
    }

    closeModalEmail() {
        this.setState({ fieldEmpty: false });
        this.setState({ modalEmail: false });
    }

    openModalEmail() {
        this.setState({ modalEmail: true })
    }

    closeModalLigacao() {
        this.setState({ fieldEmpty: false });
        this.setState({ modalLigacao: false });
    }

    openModalLigacao() {
        this.setState({ modalLigacao: true })
    }

    closeModalWhatsApp() {
        this.setState({ fieldEmpty: false });
        this.setState({ modalWhatsApp: false });
    }

    openModalWhatsApp() {
        this.setState({ modalWhatsApp: true });
    }

    callApiChannel(fieldEmail = false, fieldLigacao = false, idChannel) {

        let valueEmail = '';
        let valueLigacao = '';
        if (fieldEmail) {
            this.registerPage(547);
            valueEmail = document.getElementById('field-email').value;

            if (valueEmail === '') {
                this.setState({ fieldEmpty: true });
                return false;
            }
        } else if (fieldLigacao) {
            this.registerPage(550);
            valueLigacao = document.getElementById('field-telefone').value;

            if (valueLigacao === '') {
                this.setState({ fieldEmpty: true });
                return false;
            }
        }

        if (idChannel == 2) {
            this.registerPage(548);
        } else if (idChannel == 1) {
            this.registerPage(549);
        }

        this.setState({
            loading: true,
            modalEmail: false,
            modalLigacao: false,
            modalWhatsApp: false
        })

        axios.post(`${api[0].api}/contato/realizar-contato`,
            {
                nome: this.state.nome,
                cpf: this.state.cpf,
                email: valueEmail,
                telefone: valueLigacao,
                channel: idChannel
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
                    "Accept": "/",
                    "cache-control": "no-cache"
                }
            }).then(res => {

                if (res.data.status) {
                    if (idChannel === 1 || idChannel === 2) {
                        window.location.href = res.data.data;
                    } else if (idChannel === 5) {
                        window.location.href = '/return-contact-ligacao';
                    } else if (idChannel === 3) {
                        window.location.href = '/return-contact-email';
                    }
                } else {
                    this.setState({
                        loading: false,
                    })
                }
            })
    }

    async callExpedienteAtendimento(route) {

        return await axios.post(`${api[0].api}/contato${route}`,
            {
                token: '',
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
                    "Accept": "/",
                    "cache-control": "no-cache"
                }
            }).then(res => {

            if (res.data.status === true) {
                this.setState({
                    loading: false,
                    expedienteAtendimento: true,
                });
            } else {
                this.setState({
                    loading: false,
                    expedienteAtendimento: false,
                });
            }
        })
    }

    render() {
        return (
            <div>
                <Menu />
                {this.state.loading ? <Loading /> : ''}
                <div className="align-content-1">
                    <div className="header_2">
                        <div className="text_inicio">Atendimento</div>
                    </div>

                    {this.state.expedienteAtendimento == true  ?
                        <div className="header_3">
                            <div className="text_click">
                                Clique em uma das opções abaixo e prossiga com o atendimento:
                            </div>
                            <div className="align-bts">
                                <div className="align-bt-row">
                                    <button className="bt-default_1" onClick={() => this.openModalEmail()} id="contato-email">
                                        <div className="img_envelop"></div>
                                        <div className="text_envelop">E-mail</div>
                                    </button>
                                    <button className="bt-default_1" onClick={() => this.callApiChannel(false, false, 2)} id="contato-chat">
                                        <div className="img_chat"></div>
                                        <div className="text_chat_1">Chat</div>
                                    </button>
                                </div>
                                <div className="align-bt-row">
                                    <button className="bt-default_1" onClick={() => this.openModalWhatsApp()} id="contato-whatsapp">
                                        <div className="img_whats"></div>
                                        <div className="text_whats">WhatsApp</div>
                                    </button>
                                    <button className="bt-default_1" onClick={() => this.openModalLigacao()} id="contato-ligacao">
                                        <div className="img_phone"></div>
                                        <div className="text_phone">Ligação</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    :
                        <div className="header_3">
                            <div className="text_click">
                                No momento o atendimento está indiponível.<br></br>Estaremos atendendo no próximo dia útil
                            </div>
                        </div>
                    }

                    <div className="header_6">
                        <a href="/home">
                            <button className="bt_voltar">Voltar</button>
                        </a>
                    </div>

                    <Modal visible={this.state.modalEmail} width="400" height="300" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <div>
                            <div className="box-modal">
                                <div className="text_modal">Informe seu e-mail</div>
                                <input className="text_login" id="field-email" type="text" name="field-email" autoComplete="off" placeholder="E-MAIL"></input>
                                <div className="line_login"></div>
                                {this.state.fieldEmpty ? <div className="field-empty">INFORME SEU EMAIL</div> : ''}
                                <button className="bt_modal" id="enviar-email" onClick={() => this.callApiChannel(true, false, 3)}>ENVIAR</button>
                                <button onClick={() => this.closeModalEmail()} className="bt_modal">FECHAR</button>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={this.state.modalLigacao} width="400" height="300" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <div>
                            <div className="box-modal">
                                <div className="text_modal">Informe seu telefone:</div>
                                <input className="text_login" id="field-telefone" type="tel" name="field-telefone" autoComplete="off" placeholder="TELEFONE"></input>
                                <div className="line_login"></div>
                                {this.state.fieldEmpty ? <div className="field-empty">INFORME SEU TELEFONE</div> : ''}
                                <button className="bt_modal" id="enviar-ligacao" onClick={() => this.callApiChannel(false, true, 5)}>ENVIAR</button>
                                <button onClick={() => this.closeModalLigacao()} className="bt_modal">FECHAR</button>
                            </div>
                        </div>
                    </Modal>
                    <Modal visible={this.state.modalWhatsApp} width="400" height="300" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <div>
                            <div className="box-modal">
                                <div className="text_modal">ATENDIMENTO WHATSAPP</div>
                                <h5 className="message-modal-wpp">Adicione nosso número em seus contatos para receber o atendimento. (11) 99221-1814.</h5>
                                <button className="bt_modal" onClick={() => this.callApiChannel(false, false, 1)}>ENVIAR</button>
                                <button onClick={() => this.closeModalWhatsApp()} className="bt_modal">FECHAR</button>
                            </div>
                        </div>
                    </Modal>
                    <Footer />
                </div>
            </div>
        )
    }
};

export default Contact;