import React, { Component } from 'react';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import InputMask from 'react-input-mask';

import axios from 'axios';
import Modal from 'react-awesome-modal';
import Loading from '../Loading/Loading';
import Session from '../../Session/Session';

import api from '../Environment/Environment';
import BaseService from '../Service/BaseService';

export class Login extends BaseService {

    constructor(props) {

        super(props);
        this.state = {
            loading: false,
            session: new Session(),
            messageField: '',
            fieldEmpty: false,
            visibleModal: false,
            prod: api[0].prod,
            messages: [
                'Não localizamos dados para o CPF informado.',
                'Por favor, informe seu CPF',
                'Por favor, preencha o ReCaptcha para prosseguir.',
                'Ocorreu algum erro ao localizar seus dados, por favor tente novamente.',
                'Por favor, informe seu CPF e preencha o ReCaptcha para prosseguir.',
                'Por favor, informe um CPF válido.',
                'Autenticação inválida, tente novamente.'
            ],
        }

        this.registerPage(534);
    }



    openModal() {
        this.setState({ visibleModal: true });
    }

    login = async () => {

        let cpf = document.getElementById('cpf').value;

        if (this.state.prod) {
            var recaptcha = document.getElementById('g-recaptcha-response').value;
        }

        if (cpf === '') {
            this.setState({ messageField: this.state.messages[1], visibleModal: true });
            return false;
        } else if (recaptcha === '') {
            this.setState({ messageField: this.state.messages[2], visibleModal: true });
            return false;
        } else if (!this.validateCpf(cpf)) {
            this.setState({ messageField: this.state.messages[5], visibleModal: true });
            return false;
        } else if (this.state.prod) {
            this.callApiRecaptcha(cpf, recaptcha);
        } else {
            this.callApiConsultarClientes(cpf);
        }
    }

    callApiRecaptcha(cpf, recaptcha) {

        this.setState({ loading: true })
        axios.post(`${api[0].api}/clientes/recaptcha-validator`,
            {
                recaptcha: recaptcha
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
                    "Accept": "/",
                    "cache-control": "no-cache"
                }
            }).then(res => {

                if (res.data.status) {
                    this.callApiConsultarClientes(cpf);
                } else {
                    this.setState({ messageField: this.state.messages[1], visibleModal: true, loading: false });
                    return false;
                }
            })

    }

    callApiConsultarClientes(cpf) {

        this.setState({ loading: true })
        axios.post(`${api[0].api}/clientes/consultar-clientes`,
            {
                cpf: cpf,
                empresa: this.state.session.getEmpresa()
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
                    "Accept": "/",
                    "cache-control": "no-cache"
                }
            }).then(res => {

                if (res.data.status) {
                    let data = res.data.data;
                    let segundaVia = false;

                    this.state.session.setName(data.nome);
                    this.state.session.setCpf(data.cpf);
                    this.state.session.setContract(data.contrato);
                    this.state.session.setCodTit(data.codigoTitulo);
                    this.state.session.setValueDebt(data.valorDivida);
                    this.state.session.setAcordo(data.acordo);
                    this.state.session.setToken(data.logonToken);
                    this.state.session.setDiasAtraso(data.diasAtraso);
                    segundaVia = data.acordo ? data.acordo : false;
                    this.state.session.setSegundaVia(segundaVia);

                    this.registerPage(535);

                    data.acordo ? this.callApiSearchParcel(cpf, data.codigoTitulo) : window.location.href = '/home';
                } else {
                    this.registerPage(536);
                    this.setState({ loading: false, visibleModal: true, messageField: this.state.messages[0] })
                }
            })

    }

    async callApiSearchParcel(cpf, codTit) {

        await axios.post(`${api[0].api}/acordos/consultar-parcelas`,
            {
                cpf: cpf,
                codigoTitulo: codTit
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
                    "Accept": "/",
                    "cache-control": "no-cache"
                }
            }).then(res => {                
                if (res.data.status) {
                    this.state.session.setValueDebt(res.data.data.valorParcela);
                    localStorage.setItem('parcelas', JSON.stringify(res.data.data));
                    window.location.href = '/home';
                } else {
                    localStorage.clear();
                    this.setState({ loading: false, visibleModal: true, messageField: this.state.messages[1] })
                }
            })

    }

    async callApiAcordo(cpf, contrato) {

        var parcelas = localStorage.getItem('parcelas');
        await axios.post(`${api[0].api}/acordos/consultar-acordo`,
            {
                documento: cpf,
                credor: 236,
                empresa: this.state.empresa,
                contrato: contrato,
                boletoPendente: true,
                codParcela: parcelas.codParcela,
                segundaVia:''
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
                    "Accept": "/",
                    "cache-control": "no-cache"
                }
            }).then(res => {

                if (res.data.status) {
                    let returnApi = res.data.data;
                    this.state.session.setFileName(returnApi.fileName);
                    this.state.session.setLinhaDig(returnApi.linhaDigitavel);
                    this.state.session.setUrlBoleto(returnApi.urlBoleto);
                    this.state.session.setValueDebt(returnApi.valor);
                    this.state.session.setVencimento(returnApi.vencimento);

                    window.location.href = '/home';
                } else {
                    localStorage.clear();
                    this.setState({ loading: false, visibleModal: true, messageField: this.state.messages[1] })
                }
            })
    }

    redirect() {
        window.location.href = '/login';
    }

    validateCpf(cpf) {
        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf === '') return false;

        if (cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999")
            return false;

        let add = 0;
        for (var i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i);
        var rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(9)))
            return false;

        add = 0;
        for (i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(cpf.charAt(10)))
            return false;
        return true;
    }

    render() {
        return (
            <div className="bg">
                <Header />
                {this.state.loading ? <Loading /> : ''}
                <div className="align-content-1">
                    <div className="box">
                        <div className="text_login1">Insira seu CPF</div>
                        <InputMask mask="999.999.999.99" className="text_login" id="cpf" type="tel" name="cpf" autoComplete="off" placeholder="CPF"></InputMask>

                        <div className="line_login"></div>
                        {api[0].prod ?
                            <div className="field-recaptcha">
                                <div className="g-recaptcha" data-sitekey="6LcGBaIUAAAAAA2rJYvqiBDJRpolO7KrzgPGE69n"></div>
                            </div>
                            : ''
                        }
                        <button onClick={this.login} className="bt_login">ENTRAR</button>
                    </div>
                    <Modal visible={this.state.visibleModal} width="400" height="300" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <div>
                            <div className="box-modal">
                                <div className="text_modal">ATENÇÃO</div>
                                <h5 className="message-modal-wpp">{this.state.messageField}</h5>
                                <button onClick={() => this.redirect()} className="bt_modal">FECHAR</button>
                            </div>
                        </div>
                    </Modal>
                    <Footer />
                </div>
            </div>
        )
    }
};

export default Login;