import React, { Component }  from 'react';
import BaseService from '../Service/BaseService';


export class Header extends BaseService {
    render() {
        return (            
            <div className="">
                <div className="header">
                    <div className="align_logo">
                        <div className="logo_services"></div>
                    </div>
                </div>
            </div>
        )
    }
};

export default Header;