import React, { Component } from 'react';

import Session from '../../Session/Session';

import Menu from '../Home/MenuLateral';
import Footer from '../Footer/Footer';

import Loading from '../Loading/Loading';

import Calendar from 'react-calendar';
import Modal from 'react-awesome-modal';
import api from '../Environment/Environment';
import axios from 'axios';
import BaseService from '../Service/BaseService';

export class SelectDate extends BaseService {

    constructor(props) {

        const session = new Session();
        session.callApiToken('/validar-token');

        super(props);
        this.state = {
            session: session,
            cpf: session.getCpf(),
            name: session.getName(),
            contrato: session.getContract(),
            codTit: session.getCodTit(),
            startDate: new Date(),
            maxDate: this.calcDays(),
            dateSel: '',
            visibleModal: false
        }
        this.registerPage(538);
        this.handleChangeDate = this.handleChangeDate.bind(this);
    }

    // async registerPage(recurso) {
    //     await axios.post(`${api[0].api}/clientes/register-page`,
    //         {
    //             recurso: recurso,
    //             url: window.location.href,
    //             resolucao: window.screen.height + 'x' + window.screen.width,
    //             telefone: '',
    //             email: '',
    //             nome: this.state.name,
    //             cpf: this.state.cpf
    //         },
    //         {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Basic Y2FycmVmb3VyX2F0YWNhZGFvX2VzY29iOk1hc3RlcjEyMDkh",
    //                 "Accept": "/",
    //                 "cache-control": "no-cache"
    //             }
    //         }).then(res => {
    //         })
    // }


    calcDays() {

        var now = new Date();
        var sum = now.setDate(now.getDate() + 10);
        return new Date(sum);
    }

    handleChangeDate(date) {
        this.setState({
            dateSel: date
        });
    }

    saveSessionDateSelected() {

        if (this.state.dateSel === '') {
            this.setState({ visibleModal: true });
            return false;
        }

        localStorage.setItem('date-selected', this.state.dateSel);
        window.location.href = '/simulate-acordo';
    }

    closeModal() {
        this.setState({ visibleModal: false });
    }

    render() {
        return (
            <div>
                <Menu />
                {this.state.loading ? <Loading /> : ''}
                <div className="align-content-1">
                    <div className="header_2">
                        <div className="text_inicio">Selecionar vencimento</div>
                    </div>
                    <div className="header_3">
                        <div className="text_click">
                            <label className="text_color_1 text-capitalize">{this.state.name}</label>, selecione o dia de vencimento da primeira parcela. <br></br>
                        </div>
                        <div className="row align-center field-calendar">
                            <Calendar onChange={this.handleChangeDate} minDate={this.state.startDate} maxDate={this.state.maxDate} calendarType="US"/>
                        </div>
                        <div className="header_6">
                            <button className="bt_enviar" onClick={() => this.saveSessionDateSelected()}>Continuar</button>
                            <a href="/home">
                                <div className="header_6">
                                    <button className="bt_voltar">Voltar</button>
                                </div>
                            </a>
                        </div>
                    </div>
                    <Modal visible={this.state.visibleModal} width="400" height="300" effect="fadeInUp" onClickAway={() => this.closeModal()}>
                        <div>
                            <div className="box-modal">
                                <div className="text_modal"></div>
                                <h5 className="message-modal-wpp">Por favor, selecione melhor dia para vencimento.</h5>
                                <button onClick={() => this.closeModal()} className="bt_modal">FECHAR</button>
                            </div>
                        </div>
                    </Modal>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default SelectDate;