import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './Components/Home/Home';
import Login from './Components/Login/Login';
import Contact from './Components/Contact/Contact';
import ViewAcordo from './Components/Acordo/ViewAcordo';
import SelectDate from './Components/PayInfo/SelectDate';
import PaymentMade from './Components/PayInfo/PaymentMade';
import SelectParcel from './Components/Acordo/SelectParcel';
import ThankYouPage from './Components/Messages/ThankYouPage';
import SimulateAcordo from './Components/Acordo/SimulateAcordo';
import ReturnContactEmail from './Components/Messages/ReturnContactEmail';
import ReturnContactLigacao from './Components/Messages/ReturnContactLigacao';
import NotFound from './Components/Pages/NotFound';
import PoliticaPrivacidade from './Components/Pages/Privacidade';

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path='/' exact={true} component={App}></Route>
            <Route path='/home' exact={true} component={Home}></Route>
            <Route path='/login' exact={true} component={Login}></Route>
            <Route path='/contact' exact={true} component={Contact}></Route>            
            <Route path='/thank-you' exact={true} component={ThankYouPage}></Route>
            <Route path='/view-acordo' exact={true} component={ViewAcordo}></Route>            
            <Route path='/select-date' exact={true} component={SelectDate}></Route>
            <Route path='/payment-made' exat={true} component={PaymentMade}></Route>            
            <Route path='/select-parcel' exact={true} component={SelectParcel}></Route>
            <Route path='/simulate-acordo' exact={true} component={SimulateAcordo}></Route>
            <Route path='/return-contact-email' exact={true} component={ReturnContactEmail}></Route>
            <Route path='/return-contact-ligacao' exact={true} component={ReturnContactLigacao}></Route>
            <Route path='/politicaprivacidade' exact={true} component={PoliticaPrivacidade}></Route>
            <Route path='*' exact={true} component={NotFound}></Route>
        </Switch>
    </BrowserRouter>
    , document.getElementById('root'));
serviceWorker.unregister();
