import React, { Component } from 'react';
import BaseService from '../Service/BaseService';

export class Loading extends BaseService {

    render() {
        return (
            <div>
                <div className="loading"></div>
            </div>
        )
    }
};

export default Loading;