import React, { Component } from 'react';

import Footer from '../Footer/Footer';
import Loading from '../Loading/Loading';
import Session from '../../Session/Session';
import api from '../Environment/Environment';
import axios from 'axios';
import BaseService from '../Service/BaseService';

export class NotFound extends BaseService {

    constructor(props) {
        const session = new Session();

        super(props);
        this.state = {
            session: session,
            cpf: session.getCpf(),
            name: session.getName(),
            contract: session.getContract(),
            loading: false,
        };

        this.registerPage(691);
    }

    render() {

        return (
            <div></div>
        )
    }
};

export default NotFound;
